import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Card } from "react-bootstrap";

class NoWallet extends Component {
  render() {
    return (
      <Card className="mt-5 swap_card text-center">
        <Card.Body>Please connect your wallet to make a swap </Card.Body>
      </Card>
    );
  }
}
export default NoWallet;
