import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Card} from "react-bootstrap";


class NoMetamask extends Component {
    render() {
        return(
            <Card className="mt-5 swap_card text-center">
                <Card.Body>Please install Metamask on your browser & reload the window</Card.Body>
            </Card>
        );
    }
}
export default NoMetamask