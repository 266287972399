import uk from "../img/uk.png";
import de from "../img/de.png";
import pt from "../img/pt.png";
import fr from "../img/fr.png";
import es from "../img/es.png";
import nl from "../img/nl.png";
import ReactCountryFlag from "react-country-flag";
import { Component } from "react";
import "./Staking.css";
import StakingABI from "../contract/Staking.json";
import Web3 from "web3";
import qrCode from "../img/qrcode.png";
import OuranosContract from "../contract/OuranosToken.json";
import { Col, Row, Spinner } from "react-bootstrap";
const CoinGecko = require("coingecko-api");
const CoinGeckoClient = new CoinGecko();

const addressStaking = "0x30730DB81De92563e60dF57F890a07ac3C615d4C";
const addressToken = "0xd5966542e10A788f46526fA8118C246dD3Ea583B";

class Stake extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tickets: "",
      price: "",
      contract: "",
      balance: "",
      web3: "",
      account: "",
      token: "",
      stakeLoad: false,
      unstakeLoad: false,
      approveLoad: false,
      priceToken: "",
      eth_price: "",
      oraUsd: "",
      balanceStake: "",
      allowance: "",
    };
    this.stake = this.stake.bind(this);
    this.unStake = this.unStake.bind(this);
    this.checkAllowance = this.checkAllowance.bind(this);
    this.approve = this.approve.bind(this);
  }

  componentWillMount() {
    this.loadBlockchain();
  }

  async loadBlockchain() {
    const web3 = new Web3(
      Web3.givenProvider ||
        "https://goerli.infura.io/v3/06206481a4f74fe88303c19327443fb4"
    );
    const account = await web3.eth.getAccounts();
    this.setState({ account: account[0] });
    const contract = new web3.eth.Contract(StakingABI, addressStaking);
    const balance = await web3.eth.getBalance(this.state.account);
    const id = await web3.eth.net.getId();
    const deployedNetwork = OuranosContract.networks[id];
    const contractToken = new web3.eth.Contract(
      OuranosContract.abi,
      deployedNetwork.address
    );

    let data = await CoinGeckoClient.simple.price({
      ids: ["ethereum"],
      vs_currencies: ["usd"],
    });

    // let price = await contract.methods.priceTickets.call();
    // let tickets = await contract.methods.tickets().call();
    let priceToken = await contractToken.methods.tokenPrice().call();
    // let balances = await contract.methods.balances(account[0]).call();
    priceToken = web3.utils.fromWei(priceToken);

    let priceTickets = 6000000000000000000;
    priceTickets = 6000000000000000000 / 10 ** 18;

    let ora_price_usd = priceToken * data["data"]["ethereum"]["usd"];
    ora_price_usd = ora_price_usd.toFixed(3);
    let ora_balance_usd = ora_price_usd * priceTickets;
    ora_balance_usd = ora_balance_usd.toFixed(2);

    console.log(ora_balance_usd);

    this.setState({
      // price: price,
      // tickets: tickets,
      contract: contract,
      web3: web3,
      balance: balance,
      token: contractToken,
      priceToken: priceToken,
      eth_price: data["data"]["ethereum"]["usd"],
      oraUsd: ora_balance_usd,
      // balanceStake: balances,
    });
    this.checkAllowance();
  }

  numStr(a, b) {
    a = "" + a;
    b = b || " ";
    var c = "",
      d = 0;
    while (a.match(/^0[0-9]/)) {
      a = a.substr(1);
    }
    for (var i = a.length - 1; i >= 0; i--) {
      c = d !== 0 && d % 3 === 0 ? a[i] + b + c : a[i] + c;
      d++;
    }
    return c;
  }

  async stake() {
    this.setState({ stakeLoad: true });
    let stake = await this.state.contract.methods
      .stakeTokens(this.state.price)
      .send({ from: this.state.account })
      .on("receipt", (hash) => {
        alert("Tokens staked ✅");
        this.setState({ stakeLoad: true });
      })
      .on("error", (error) => {
        this.setState({ stakeLoad: false });
      });

    this.setState({ stakeLoad: false });
    this.loadBlockchain();
  }

  async unStake() {
    this.setState({ unstakeLoad: true });
    await this.state.contract.methods
      .unStakeTokens(this.state.price)
      .send({ from: this.state.account })
      .on("receipt", (hash) => {
        alert("Tokens unstaked ✅");
      })
      .on("error", (error) => {
        this.setState({ unstakeLoad: false });
      });
    this.setState({ unstakeLoad: false });
    this.loadBlockchain();
  }

  async approve() {
    this.setState({ approveLoad: true });
    let approve = await this.state.token.methods
      .increaseAllowance(addressStaking, "20000000000000000000000")
      .send({ from: this.state.account });
    this.setState({ approveLoad: false });
    this.loadBlockchain();
  }

  async checkAllowance() {
    try {
      let allowanceNumber = await this.state.token.methods
        .allowance(this.state.account, addressStaking)
        .call();

      this.setState({ allowance: allowanceNumber });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    function numStr(a, b) {
      a = "" + a;
      b = b || " ";
      var c = "",
        d = 0;
      while (a.match(/^0[0-9]/)) {
        a = a.substr(1);
      }
      for (var i = a.length - 1; i >= 0; i--) {
        c = d !== 0 && d % 3 === 0 ? a[i] + b + c : a[i] + c;
        d++;
      }
      return c;
    }
    const etherValue = Web3.utils.fromWei(this.state.price, "ether");
    return (
      <div className="staking">
        <div className="text-center mb-4 staking-img">
          <img src={qrCode} width={120} />
        </div>

        <div className="text-center oraPerTicket">
          <p>
            {this.state.account.slice(0, 8) +
              "*******" +
              this.state.account.slice(38, this.state.account.length)}
          </p>
        </div>

        <div className="text-center movie mt-4">
          <div className="bloc-movie">
            <p>KARA THE MOVIE</p>
            <p>
              {this.state.tickets} 250 000 QR TICKETS LEFT <br /> PREMIERE
              SCREENINGS IN{" "}
            </p>
            <Row className="mt-2">
              <Col className="col-left">
                <div className="mt-2">
                  <p>
                    <img src={uk} width={20} /> London
                  </p>
                </div>
                <div className="mt-2">
                  <p>
                    <img src={de} width={20} /> Berlin
                  </p>
                </div>
                <div className="mt-2">
                  <p>
                    <img src={pt} width={20} /> Lisbon
                  </p>
                </div>
              </Col>
              <Col className="col-right">
                <div className="mt-2">
                  <p>
                    <img src={fr} width={20} /> Paris
                  </p>
                </div>
                <div className="mt-2">
                  <p>
                    <img src={nl} width={20} /> Amsterdam
                  </p>
                </div>
                <div className="mt-2">
                  <p>
                    <img src={es} width={20} /> Madrid
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div className="text-center mt-4 oraPerTicket">
          <div className="bloc-movie">
            <p>6 ORA / TICKET</p>
            <span className="balance"> {this.state.oraUsd} $ in USD value</span>
          </div>
        </div>

        {this.state.allowance >= "20000000000000000000000" ? (
          <div className="d-flex justify-content-between mt-4 btn-staking gap-4">
            <button
              onClick={this.stake}
              // disabled={this.state.balanceStake > 0 && true}
              disabled={true}
              className="btn-unstake"
            >
              {this.state.stakeLoad ? (
                <Spinner animation="border" role="status" color="#FFF">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                <span>
                  STAKE <br />{" "}
                  <span className="qr-activate"> (activate QR) </span>
                </span>
              )}
            </button>
            <button
              onClick={this.unStake}
              // disabled={this.state.balanceStake == 0 && true}
              disabled={true}
              className="btn-unstake"
            >
              {this.state.unstakeLoad ? (
                <Spinner animation="border" role="status" color="#FFF">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                <span>
                  UNSTAKE <br />{" "}
                  <span className="qr-activate"> (deactivate QR) </span>
                </span>
              )}
            </button>
          </div>
        ) : (
          <div
            // onClick={this.approve}
            className="text-center mt-4 oraPerTicket approve"
          >
            {this.state.approveLoad ? (
              <Spinner animation="border" role="status" color="#FFF">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              <p>Grant Staking approval </p>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default Stake;
