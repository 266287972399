import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Card } from "react-bootstrap";
import NoMetamask from "./NoMetamask";

class ChangeNetwork extends Component {
  componentDidMount = () => {
    this.detectEthereumNetwork();
  };
  detectEthereumNetwork = () => {
    if (window.ethereum === "undefined") {
      return <NoMetamask />;
    } else {
      window.ethereum.enable();
      window.ethereum.on("networkChanged", function (network) {
        if (network === "1") {
          document.location.reload();
        }
      });
    }
  };
  render() {
    return (
      <Card className="mt-5 swap_card text-center">
        <Card.Body>Please connect to the Ethereum Mainnet</Card.Body>
      </Card>
    );
  }
}
export default ChangeNetwork;
