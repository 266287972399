import React, {Component} from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";

class ProgressBarCrowdsale extends Component{
    render() {
        let minted = parseInt(this.props.amount_minted)
        let value = (minted/100000000)*100

        return (
            <ProgressBar percent={value}>
                <Step>
                    {({accomplished, index, nada, position}) => (
                        <div
                            className={`indexedStep mb-4 ${accomplished ? "accomplished" : null}`}
                        >
                            <p></p>
                        </div>
                    )}
                </Step>
                <Step>
                    {({accomplished, index, nada, position}) => (
                        <div
                            className={`indexedStep mb-4 col-8 ${accomplished ? "accomplished" : null}`}
                        >
                            <span className="balance">Early User Soft Cap</span>
                            <p className="mt-1">|</p>
                        </div>
                    )}
                </Step>
                <Step>
                    {({accomplished, index}) => (
                        <div
                            className={`indexedStep mb-4 col-8 ${accomplished ? "accomplished" : null}`}
                        >
                            <span className="balance">Pre-sales Soft Cap</span>
                            <p className="mt-1">|</p>
                        </div>
                    )}
                </Step>
                <Step>
                    {({accomplished, index}) => (
                        <div
                            className={`indexedStep mb-4 ${accomplished ? "accomplished" : null}`}
                        >
                            <p></p>
                        </div>
                    )}
                </Step>
            </ProgressBar>

        );
    }
}

export default ProgressBarCrowdsale