import React, { Component } from "react";
import OuranosContract from "./contract/OuranosToken.json";
import getWeb3 from "./getWeb3";
import Swap from "./Swap";
import "./App.css";
import ChangeNetwork from "./ChangeNetwork";
import NoWallet from "./NoWallet";
import NoMetamask from "./NoMetamask";
import Main from "./Main";

class App extends Component {
  state = {
    storageValue: 0,
    web3: null,
    accounts: null,
    contract: null,
    network: null,
  };

  componentDidMount = async () => {
    try {
      // Get network provider and web3 instance.
      const web3 = await getWeb3();

      // Use web3 to get the user's accounts.
      const accounts = await web3.eth.getAccounts();

      // Get the contract instance.
      const networkId = await web3.eth.net.getId();
      const deployedNetwork = OuranosContract.networks[networkId];
      const instance = new web3.eth.Contract(
        OuranosContract.abi,
        deployedNetwork && deployedNetwork.address
      );

      const network = await web3.eth.net.getNetworkType();

      // Set web3, accounts, and contract to the state, and then proceed with an
      // example of interacting with the contract's methods.
      this.setState({ web3, accounts, contract: instance }, this.runExample);
      this.setState({ network: network });
    } catch (error) {
      // Catch any errors for any of the above operations.
      alert(
        `Failed to load web3, accounts, or contract. Check console for details.`
      );
      console.error(error);
    }
  };

  render() {
    if (window.ethereum !== "undefined" || window.web3 !== "undefined") {
      if (this.state.web3) {
        if (this.state.network === "main") {
          return <Main />;
        } else {
          return <ChangeNetwork />;
        }
      } else {
        console.log("no wallet");
        return <NoWallet />;
      }
    } else {
      console.log("no metamask");
      return <NoMetamask />;
    }
  }
}
export default App;
