import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Col, Row, Card, Spinner } from "react-bootstrap";
import OuranosContract from "./contract/OuranosToken.json";
import "./Swap.css";
import Web3 from "web3";
import Countdown from "react-countdown";
import eth_logo from "./img/eth_logo.png";
import ProgressBarCrowdsale from "./ProgressBar";
const CoinGecko = require("coingecko-api");
const CoinGeckoClient = new CoinGecko();

class Swap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eth_price: null,
      amount_eth: 0.0,
      amount_ora: 0.0,
      account: "",
      balance: "",
      ora_balance: "",
      reference: "eth",
      web3: "",
      ora_price: "",
      contract: "",
      amount_minted: 0,
      locked_value: 0,
      swapLoad: false,
    };
    this.handleChangeETH = this.handleChangeETH.bind(this);
    this.handleChangeORA = this.handleChangeORA.bind(this);
    this.oraConvert = this.oraConvert.bind(this);
  }

  componentDidMount = async () => {
    try {
      let data = await CoinGeckoClient.simple.price({
        ids: ["ethereum"],
        vs_currencies: ["usd"],
      });

      this.setState({ eth_price: data["data"]["ethereum"]["usd"] });
    } catch (error) {
      console.error(error);
    }
  };

  componentWillMount() {
    this.loadBlockchain();
    this.loadSwapInput();
    this.loadSwapOutput();
  }

  async loadBlockchain() {
    const web3 = new Web3(
      Web3.givenProvider ||
        "https://goerli.infura.io/v3/06206481a4f74fe88303c19327443fb4"
    );
    const account = await web3.eth.getAccounts();
    this.setState({ account: account[0] });
    const id = await web3.eth.net.getId();
    const deployedNetwork = OuranosContract.networks[id];
    const contract = new web3.eth.Contract(
      OuranosContract.abi,
      deployedNetwork.address
    );
    const balance = await web3.eth.getBalance(this.state.account);
    this.setState({ contract: contract });
    this.setState({ balance: web3.utils.fromWei(balance) });
    this.setState({ web3: web3 });
    this.loadOraPrice();
    this.loadAmountMinted();
    this.loadOraBalance();
  }

  handleChangeETH(event) {
    this.setState({ amount_eth: event.target.value });
    this.oraConvert(event);
  }
  handleChangeORA(event) {
    let amount_ora = event.target.value;
    let Float = this.state.ora_price * amount_ora;
    let amount_eth = Float.toFixed(6);
    this.setState({ amount_eth: amount_eth });
    this.setState({ amount_ora: amount_ora });
  }
  oraConvert(event) {
    let eth = event.target.value;
    let Float = eth / this.state.ora_price;
    let ora = Float.toFixed(3);
    this.setState({ amount_ora: ora });
  }
  handleMaxBalance = (event) => {
    event.preventDefault();
    let balance = this.state.balance;
    this.setState({ amount_eth: balance });
    let Float = balance / this.state.ora_price;
    let ora = Float.toFixed(3);
    this.setState({ amount_ora: ora });
  };

  loadOraPrice = async () => {
    let contract = this.state.contract;
    let price = await contract.methods.tokenPrice().call();
    const web3 = this.state.web3;
    this.setState({ ora_price: web3.utils.fromWei(price) });
  };

  loadOraBalance = async () => {
    const contract = this.state.contract;
    const address = this.state.account;
    let balance = await contract.methods.balanceOf(address).call();

    this.setState({ ora_balance: balance });
  };

  loadAmountMinted = async () => {
    let contract = this.state.contract;
    let weiValue = await contract.methods.totalSupply().call();
    const web3 = this.state.web3;
    let amount = web3.utils.fromWei(weiValue);
    amount = parseInt(amount);
    this.setState({ amount_minted: amount });
    this.loadLockedValue();
  };

  loadLockedValue = async () => {
    let eth_price = this.state.eth_price;
    let amount_minted = this.state.amount_minted;
    let amount_in_eth = amount_minted * this.state.ora_price;
    let totalvalue = eth_price * amount_in_eth;

    this.setState({ locked_value: totalvalue.toFixed(2) });
  };

  handleSwap = async () => {
    this.setState({ swapLoad: true });
    let web3 = this.state.web3;
    let weiValue = web3.utils.toWei(this.state.amount_eth, "ether");
    const contract = this.state.contract;
    const address = this.state.account;
    const gas = await contract.methods
      .mint()
      .estimateGas({ from: address, value: weiValue });

    await contract.methods.mint().send({
      from: address,
      value: weiValue,
      gas,
    });

    this.setState({ swapLoad: false });
    this.loadBlockchain();
  };

  removePlaceHolder = () => {
    if (this.state.amount_eth <= 0) {
      this.setState({ amount_eth: "" });
      this.setState({ amount_ora: "" });
    }
  };

  loadSwapInput = () => {
    let balance = this.state.balance;
    balance = parseFloat(balance);
    balance = balance.toFixed(5);
    if (this.state.reference === "eth") {
      return (
        <>
          <div className="swap_input">
            <div className="row d-flex align-items-center swap_row">
              <div className="col col-sm-6 col-md-5 col-lg-5 col-xl-5 px-4">
                <div className="currency_box px-2 py-1 mt-4 logo-eth">
                  <div className="row d-flex align-items-center">
                    <div className="col">
                      <img src={eth_logo} className="img_logo" alt="logo_eth" />
                    </div>
                    <div className="col">
                      <span>ETH</span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col col-sm-6 col-md-5 mt-4 col-lg-5 col-xl-5  d-flex align-items-center"
                onClick={this.removePlaceHolder}
              >
                <Form.Control
                  className="input_currency py-2"
                  value={this.state.amount_eth}
                  onChange={this.handleChangeETH}
                />
              </div>
            </div>
            <div className="row px-3">
              <div className="col">
                <div onClick={this.handleMaxBalance} className="hover_balance">
                  {" "}
                  <span className="balance"> Balance: {balance} ETH</span>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="swap_input">
            <div className="row d-flex align-items-center swap_row">
              <div className="col col-sm-6 col-md-5 col-lg-5 col-xl-5 px-4">
                <div className="currency_box px-2 py-1 my-4">
                  <div className="row d-flex align-items-center">
                    <div className="col ">
                      <img src={eth_logo} className="img_logo" alt="logo_eth" />
                    </div>
                    <div className="col col-xs-12">
                      <span>ORA</span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col col-sm-6 col-md-5 col-lg-5 col-xl-5 d-flex align-items-center"
                onClick={this.removePlaceHolder}
              >
                <Form.Control
                  className="input_currency py-2"
                  value={this.state.amount_ora}
                  onChange={this.handleChangeORA}
                />
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  loadSwapOutput = () => {
    let balance = this.state.balance;
    balance = parseFloat(balance);
    balance = balance.toFixed(5);

    if (this.state.reference === "ora") {
      return (
        <>
          <div className="swap_input">
            <div className="row d-flex align-items-center swap_row">
              <div className="col col-sm-6 col-md-5 col-lg-5 col-xl-5 px-4">
                <div className="currency_box px-2 py-1 mt-4 logo-eth">
                  <div className="row d-flex align-items-center">
                    <div className="col">
                      <img src={eth_logo} className="img_logo" alt="logo_eth" />
                    </div>
                    <div className="col">
                      <span>ETH</span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col col-sm-6 col-md-5 mt-4 col-lg-5 col-xl-5  d-flex align-items-center"
                onClick={this.removePlaceHolder}
              >
                <Form.Control
                  className="input_currency py-2"
                  value={this.state.amount_eth}
                  onChange={this.handleChangeETH}
                />
              </div>
            </div>
            <div className="row px-3">
              <div className="col">
                <div onClick={this.handleMaxBalance} className="hover_balance">
                  {" "}
                  <span className="balance"> Balance: {balance} ETH</span>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="swap_input">
            <div className="row d-flex align-items-center swap_row">
              <div className="col col-sm-6 col-md-5 col-lg-5 col-xl-5 px-4">
                <div className="currency_box  px-2 py-1 my-4 logo-eth">
                  <div className="row d-flex align-items-center ">
                    <div className="col">
                      <img src={eth_logo} className="img_logo" alt="logo_eth" />
                    </div>
                    <div className="col">
                      <span>ORA</span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col col-sm-6 col-md-5 col-lg-5 col-xl-5 d-flex align-items-center"
                onClick={this.removePlaceHolder}
              >
                <Form.Control
                  className="input_currency py-2"
                  value={this.state.amount_ora}
                  onChange={this.handleChangeORA}
                />
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  changeSwap = (event) => {
    console.log(event);
    if (event.type === "click") {
      if (this.state.reference === "eth") {
        this.setState({ reference: "ora" });
      } else {
        this.setState({ reference: "eth" });
      }
    }
  };

  render() {
    let account = this.state.account;
    let ora_price = this.state.ora_price;
    let amount_minted = this.state.amount_minted;
    let locked_value = this.state.locked_value;
    let ora_balance = this.state.ora_balance;
    ora_balance = ora_balance / 10 ** 18;

    let eth_price = this.state.eth_price;
    let ora_price_usd = ora_price * eth_price;
    ora_price_usd = ora_price_usd.toFixed(3);
    let ora_balance_usd = ora_price_usd * ora_balance;
    ora_balance_usd = ora_balance_usd.toFixed(3);

    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = today.getFullYear();
    today = dd + "/" + mm + "/" + yyyy;

    function numStr(a, b) {
      a = "" + a;
      b = b || " ";
      var c = "",
        d = 0;
      while (a.match(/^0[0-9]/)) {
        a = a.substr(1);
      }
      for (var i = a.length - 1; i >= 0; i--) {
        c = d !== 0 && d % 3 === 0 ? a[i] + b + c : a[i] + c;
        d++;
      }
      return c;
    }

    let props_for_progress_bar = amount_minted;

    amount_minted = numStr(amount_minted, " ");
    locked_value = numStr(parseInt(locked_value), "");
    ora_balance = numStr(parseInt(ora_balance), "");
    ora_balance_usd = numStr(parseInt(ora_balance_usd), "");

    return (
      <>
        {/* <Row className="text-center my-3 px-5">
          <div className="card">
            <div className="card-body">
              <span>
                <small>{account}</small>
              </span>
            </div>
          </div>
        </Row> */}

        <div className="info_token">
          <div className="info_token-bloc text-center px-3 py-4">
            <span>Max Cap: 100 000 000 ORA</span>
            <br />
            <div className="mt-3">
              <span>Minted Total: {amount_minted} ORA</span>
              <br />
              <span className="balance">{locked_value} $ in usd value</span>
              <br />
            </div>
          </div>
        </div>

        <div className="token_value mt-3">
          <div className="token_value-bloc text-center">
            <span className="p-0">ORA Token value on {today}</span>
            <br />
            <span className="p-0">1 ORA = {ora_price} ETH</span>
            <br />
            <span className="balance">{ora_price_usd} $ in USD value</span>

            <div className="mt-3 mb-3">
              <span className="mt-2 p-0">YOU HAVE: {ora_balance} ORA</span>
              <br />
              <span className="balance">{ora_balance_usd} $ in USD value</span>
              <br />
            </div>

            <span className="balance mt-3">
              Lock up period until we reach DEXs
            </span>
          </div>
        </div>

        <div className="swap mt-3">
          <div className="swap_bloc">
            <Form>
              <Form.Group controlId="exampleForm.ControlInput1">
                <div className="swap-currency-input">
                  {this.loadSwapInput()}
                </div>
                <Row className="justify-content-center my-n1">
                  <div className="col-1 " onClick={this.changeSwap}>
                    <span className=" swap_arrow change_swap px-3 py-1">↓</span>
                  </div>
                </Row>
                <div className="swap-currency-output">
                  {this.loadSwapOutput()}
                </div>
              </Form.Group>
            </Form>
            <Row className="mt-3 mb-3">
              <div className="col-12">
                <button
                  style={{ width: "100%" }}
                  onClick={this.handleSwap}
                  className="btn btn-secondary btn-lg btn-block"
                >
                  {this.state.swapLoad ? (
                    <Spinner animation="border" role="status" color="#FFF">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : (
                    "Swap"
                  )}
                </button>
              </div>
            </Row>
          </div>
        </div>

        <Row className="mt-3 whitepaper mb-5">
          <Col className="text-center">
            <div className="whitepaper_bloc-black">
              <div className="whitepaper_bloc-grey">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://drive.google.com/file/d/1Lm6W_6VRtbndFlWW7B42YK2m-T5XN9tm/view"
                >
                  WHITE PAPER (FR)
                </a>
              </div>
            </div>
          </Col>
          <Col className="text-center">
            <div className="whitepaper_bloc-black">
              <div className="whitepaper_bloc-grey">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://drive.google.com/file/d/1G5WLULfQFT7vo7v8qt2M2sb7G9PaQe14/view"
                >
                  WHITE PAPER (EN)
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}
export default Swap;
