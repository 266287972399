import { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Swap from "./Swap";
import Stake from "./Staking/Staking";
import Nft from "./Nft/Nft";

class Main extends Component {
  render() {
    return (
      <section>
        <Container fluid className="mt-5">
          <Row>
            <Col
              md={5}
              style={{
                paddingLeft: "50px",
              }}
              className="col-un"
            >
              <Swap />
            </Col>
            <Col md={3} style={{ paddingLeft: "10px", paddingRight: "10px" }}>
              <Stake />
            </Col>
            <Col className="col-trois" md={4} style={{ paddingRight: "50px" }}>
              <Nft />
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default Main;
