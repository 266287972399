import { Component } from "react";
import "./Nft.css";
import nft from "../img/nft.jpg";

class Nft extends Component {
  render() {
    return (
      <div className="nft-container">
        <div className="text-center mb-4 nft-img">
          <img src={nft} width={120} />
        </div>
        <div className="text-center nft">
          <div className="bloc-nft">
            <p>KARA GENESIS NFT</p>
            <p>640 NFTs LEFT</p>
          </div>
        </div>
        <div className="text-center mt-4 bloc-info_nft">
          <p>100 ORA TO UNLOCK</p>
        </div>
        <div className="text-center mt-4 bloc-info_nft">
          <p>0.1 ETH TO MINT</p>
        </div>
        <div className="text-center nft mt-4">
          <div className="bloc-reward">
            <p>MY REWARD</p>
            <p>UP TO DATE : 0.0 ETH</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Nft;
